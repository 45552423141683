export const ROLE_ID = {
  Admin: 1,
  User: 2,
  Hr: 11,
  HrManager: 52,
};

export const BENEFIT_CLAIM_REQUEST_STATUS_TYPE = {
  All: 'All',
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Canceled: 'Canceled',
};
