import React, { Component, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Divider, message, Button, Modal, Col, Avatar, Spin } from 'antd';
import { MedicalClaimService } from '../../services/api';
import { AppContext } from '../context/AppContext';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import { AwsService } from '../../services/api';

import {
  FileOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons';
import { Text, Tag } from '../../components';
import { FeatureFlags } from '../context/FeatureFlagContext';
import defaultProfileImage from '../../assets/icons/profileimage.svg';
import systemProfileImage from '../../assets/images/system_profile.jpg';
import { FileImageOutlined } from '@ant-design/icons';
import fileImage from '../../assets/images/fileimg.png';
import { Circle } from '../Circle';
import { getMedicalClaimPic } from '../../services/image';

const EditMedicalClaimModal = (props) => {
  const { features } = useContext(FeatureFlags);
  const { userState } = useContext(AppContext);

  const { open, onCancel, onFetch, record } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCertificate, setIsLoadingCertificate] = useState(false);
  const [isLoadingInvoice, setIsLoadingInvoice] = useState(false);
  const [isUser, setIsUser] = useState(true);
  const [remarkText, setRemarkText] = useState();

  useEffect(() => {
    if (userState.currentRoleId === 2) {
      setIsUser(true);
    } else {
      setIsUser(false);
    }
  }, [userState]);

  const onCancelMedicalClaimEdit = () => {
    setRemarkText(null);
    onCancel();
  };

  //Note: temporary. migrating to new UI <DocumentDisplay>
  const handleDownloadFile = (filePath) => {
    AwsService.getFile(
      filePath,
      ({ data }) => {
        window.open(data?.url, '_blank');
      },
      (response) => {
        if (response) {
          message.error(
            `Error: ${response.data.message}. Please try again later`,
          );
        }
      },
    );
  };

  const handleDownloadMedicalPic = async (path) => {
    const pic = await getMedicalClaimPic(path);
    const anchor = document.createElement('a');
    anchor.href = pic;
    anchor.download = path;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(pic);
    setIsLoadingCertificate(false);
    setIsLoadingInvoice(false);
  };

  const onFinish = (requestStatusId) => {
    setIsLoading(true);
    if (isUser) {
      const payload = {
        requestStatusId: 400,
      };
      MedicalClaimService.updateMedicalClaimByUser(
        record.medicalClaimRequestId,
        payload,
        ({ data }) => {
          setIsLoading(false);
          message.success('Medical claim has been updated successfully.');
          setTimeout(() => {
            onFetch();
          }, 1000);
          onCancelMedicalClaimEdit();
        },
        (response) => {
          setIsLoading(false);
          if (response) {
            message.error(
              `Failed to update medical claim: ${response.data.message}`,
            );
          }
        },
      );
    } else {
      const payload = {
        medicalClaimRequestId: record.medicalClaimRequestId,
        requestStatusId: requestStatusId,
        remark: remarkText,
      };
      MedicalClaimService.updateMedicalClaim(
        payload,
        ({ data }) => {
          setIsLoading(false);
          message.success('Medical claim has been updated successfully.');
          setTimeout(() => {
            onFetch();
          }, 1000);
          onCancelMedicalClaimEdit();
        },
        (response) => {
          setIsLoading(false);
          if (response)
            message.error(
              `Failed to update medical claim: ${response.data.message}`,
            );
        },
      );
    }
  };

  return (
    <Modal
      width="500px"
      bodyStyle={{
        maxHeight: '640px',
      }}
      open={open}
      centered
      footer={
        record?.requestStatusDisplay === 'Pending' ? (
          !isUser && features?.medicalClaimApproveRejectRequest ? (
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <TextArea
                    value={remarkText}
                    onChange={(e) => {
                      setRemarkText(e.target.value);
                    }}
                    placeholder="Description"
                    autoSize={{ minRows: 2, maxRows: 4 }}
                  />
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[8, 8]}>
                    <Col>
                      <Button
                        loading={isLoading}
                        className="button-red"
                        onClick={() => onFinish(300)}
                        disabled={!remarkText}
                      >
                        Reject
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        loading={isLoading}
                        className="button-primary"
                        type="primary"
                        onClick={() => onFinish(200)}
                      >
                        Approve
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : (
            isUser && (
              <Col span={24}>
                <Row justify="end" gutter={[8, 8]}>
                  <Col>
                    <Button
                      loading={isLoading}
                      className="button-red"
                      onClick={onFinish}
                    >
                      Cancel Request
                    </Button>
                  </Col>
                </Row>
              </Col>
            )
          )
        ) : record?.requestStatusDisplay !== 'Canceled' ? (
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text small12 className="text-normal">
                    {record
                      ? record.requestStatusDisplay === 'Approved'
                        ? 'Approved by'
                        : record.requestStatusDisplay === 'Canceled by Approver'
                        ? 'Canceled by'
                        : record.requestStatusDisplay === 'Rejected'
                        ? 'Rejected by'
                        : 'Updated by'
                      : ''}
                  </Text>
                </Col>
                <Col>
                  <Row gutter={[8, 16]} align="middle">
                    <Col>
                      <Circle
                        style={{
                          height: '16px',
                          width: '16px',
                        }}
                        onError={(e) => {
                          e.target.src = defaultProfileImage;
                        }}
                        src={
                          record?.updatedByUserInfo?.picturePath
                            ? record?.updatedByUserInfo?.picturePath
                            : !record?.updatedByUserInfo?.firstnameEn &&
                              !record?.updatedByUserInfo?.lastnameEn
                            ? systemProfileImage
                            : defaultProfileImage
                        }
                      />
                    </Col>
                    <Col>
                      <Text sub4 className="text-normal">
                        {record?.updatedByUserInfo?.firstnameEn ||
                        record?.updatedByUserInfo?.lastnameEn
                          ? record?.updatedByUserInfo?.firstnameEn +
                            ' ' +
                            record?.updatedByUserInfo?.lastnameEn
                          : 'System Auto-Approval'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text small12 className="text-normal">
                    Status Date
                  </Text>
                </Col>
                <Col>
                  <Text sub4 className="text-normal">
                    {moment(record?.updateDatetime).format(
                      'DD MMMM YYYY, HH:mm',
                    )}
                  </Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]} justify="space-between" align="middle">
                <Col>
                  <Text small12 className="text-normal">
                    Description
                  </Text>
                </Col>
                <Col>
                  <Text sub4 className="text-normal">
                    {record?.remark && record?.remark != ''
                      ? record?.remark
                      : '-'}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null
      }
      onCancel={onCancelMedicalClaimEdit}
      maskClosable
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Row gutter={[16, 16]} justify="space-between" align="middle">
            <Col span={4}>
              <Circle
                style={{ width: '56px', height: '56px' }}
                src={
                  record?.userInfo?.picturePath
                    ? record?.userInfo?.picturePath
                    : defaultProfileImage
                }
                onError={(e) => {
                  e.target.src = defaultProfileImage;
                }}
              />
            </Col>
            <Col span={20}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Text h4 className="text-normal">
                    {record?.userInfo?.firstnameEn}{' '}
                    {record?.userInfo?.lastnameEn}
                  </Text>
                </Col>
                <Col flex>
                  <Text sub4 color="gray">
                    Position:{' '}
                  </Text>
                  <Text small12 className="text-normal">
                    {record?.userInfo?.position}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row justify="space-between" align="middle" gutter={[8, 8]}>
              <Col>
                <MedicineBoxOutlined
                  className="text-secondary-red"
                  style={{ marginRight: 5 }}
                />
                <Text sub4 className="text-normal">
                  {record?.medicalClaimName}
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-secondary-red">
                  {record?.runningNumber}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Treatment Date
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {moment(record?.effectiveDate).format('DD/MM/YYYY')}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Invoice / Receipt Number
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {record?.referenceNumber}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Amount
                </Text>
              </Col>
              <Col>
                <Text sub4 className="text-normal">
                  {record?.amountUse?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  Baht
                </Text>
              </Col>
            </Row>
          </Col>
          <Divider style={{ margin: 0 }} />
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Text small12 className="text-normal">
                  Reason
                </Text>
              </Col>
              <Col span={24}>
                <Text sub4 className="text-normal">
                  {record?.reason ? record?.reason : '-'}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="bg-color" style={{ padding: '6px' }}>
                  <Row
                    style={{ cursor: 'pointer' }}
                    gutter={[16, 16]}
                    direction="column"
                    justify="left"
                    onClick={() => {
                      setIsLoadingInvoice(true);
                      record?.medicalInvoicePicturePath.startsWith('users/')
                        ? handleDownloadFile(record?.medicalInvoicePicturePath)
                        : handleDownloadMedicalPic(
                            record?.medicalInvoicePicturePath,
                          );
                      setIsLoadingCertificate(false);
                      setIsLoadingInvoice(false);
                    }}
                  >
                    <Col span={6}>
                      {isLoadingInvoice ? (
                        <Row justify="center">
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            loading={isLoadingInvoice}
                            style={{ fontSize: '32px' }}
                          />
                        </Row>
                      ) : record?.medicalInvoicePicturePath.split('.')[1] !=
                        'pdf' ? (
                        <FileImageOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      ) : record?.medicalInvoicePicturePath.split('.')[1] ==
                        'pdf' ? (
                        <FilePdfOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      ) : (
                        <FileOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      )}
                    </Col>
                    <Col
                      span={18}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Row align="middle">
                        <Text sub4 className="text-normal">
                          Medical Invoice{' '}
                        </Text>
                        {!record?.medicalInvoicePicturePath && (
                          <Text sub4 className="text-secondary-red">
                            (File not found)
                          </Text>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div className="bg-color" style={{ padding: '6px' }}>
                  <Row
                    style={{ cursor: 'pointer' }}
                    gutter={[16, 16]}
                    direction="column"
                    justify="left"
                    onClick={() => {
                      setIsLoadingCertificate();
                      record?.medicalCertificatePicturePath.startsWith('users/')
                        ? handleDownloadFile(
                            record?.medicalCertificatePicturePath,
                          )
                        : handleDownloadMedicalPic(
                            record?.medicalCertificatePicturePath,
                          );
                      setIsLoadingCertificate(false);
                      setIsLoadingInvoice(false);
                    }}
                  >
                    <Col span={6}>
                      {isLoadingCertificate ? (
                        <Row justify="center">
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            loading={isLoadingCertificate}
                            style={{ fontSize: '32px' }}
                          />
                        </Row>
                      ) : record?.medicalCertificatePicturePath.split('.')[1] !=
                        'pdf' ? (
                        <FileImageOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      ) : record?.medicalCertificatePicturePath.split('.')[1] ==
                        'pdf' ? (
                        <FilePdfOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      ) : (
                        <FileOutlined
                          style={{ color: 'black', fontSize: '48px' }}
                        />
                      )}
                    </Col>
                    <Col
                      span={18}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Row>
                        <Text sub4 className="text-normal">
                          Medical Certificate{' '}
                        </Text>
                        {!record?.medicalCertificatePicturePath && (
                          <Text sub4 className="text-secondary-red">
                            (File not found)
                          </Text>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text small12 className="text-normal">
                  Status
                </Text>
              </Col>
              <Col>
                {record?.requestStatusDisplay === 'Pending' ? (
                  <Tag yellow>
                    <Text small12>Pending</Text>
                  </Tag>
                ) : record?.requestStatusDisplay === 'Approved' ? (
                  <Tag green>
                    <Text small12>Approved</Text>
                  </Tag>
                ) : record?.requestStatusDisplay === 'Rejected' ? (
                  <Tag red>
                    <Text small12>Rejected</Text>
                  </Tag>
                ) : record?.requestStatusDisplay === 'Canceled by Approver' ? (
                  <Tag grey>
                    <Text small12>Canceled by Approver</Text>
                  </Tag>
                ) : (
                  <Tag grey>
                    <Text small12>Canceled</Text>
                  </Tag>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};
export default EditMedicalClaimModal;
