import React from 'react';
import { Row, Col, Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Text } from './Text';
import PhotoIcon from '../assets/icons/photoIcon.svg';
import PdfIcon from '../assets/icons/pdfIcon.svg';

// api
import { AwsService } from '../services/api';

function DocumentDisplay({ titles, subtitles, files, ZipName }) {
  const getFileExtension = (fileName) => {
    return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
  };

  const getIcon = (fileExtension) => {
    return fileExtension === 'pdf' ? PdfIcon : PhotoIcon;
  };

  const handleDownloadFile = (file) => {
    AwsService.getFile(
      file?.filePath,
      ({ data }) => {
        window.open(data?.url, '_blank');
      },
      (response) => {
        if (response) {
          message.error(
            `Error: ${response.data.message}. Please try again later`,
          );
        }
      },
    );
  };

  const handleDownloadFiles = () => {
    const filePaths = files
      .filter((file) => file.length > 0)
      .flat()
      .map((file) => file.filePath);

    AwsService.getFiles(
      { filePaths },
      ({ data }) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.download = `${ZipName}.zip`;
        link.click();

        window.URL.revokeObjectURL(url);
      },
      (response) => {
        if (response) {
          message.error(
            `Error: ${response.data.message}. Please try again later`,
          );
        }
      },
    );
  };

  return (
    <>
      {titles.map((title, titleIndex) => {
        const associatedFiles = files[titleIndex] || [];
        const associatedSubtitle = subtitles[titleIndex] || '';

        if (associatedFiles.length === 0) {
          return null;
        }

        return (
          <div
            key={titleIndex}
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            {/* Title */}
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} md={12}>
                <Text sub3>{title}</Text>
              </Col>

              <Col xs={24} md={12}>
                <Button
                  icon={<DownloadOutlined />}
                  style={{ float: 'right' }}
                  className="button-outlined-stroke"
                  onClick={handleDownloadFiles}
                >
                  Download All
                </Button>
              </Col>
            </Row>

            <Row gutter={[0, 16]} className="card-container-bg-grey">
              {/* Subtitle */}
              <Col span={24}>
                <Row gutter={[16, 16]} align={'middle'}>
                  <Col xs={{ span: 12 }} md={{ span: 24 }}>
                    <Text sub3 className="text-normal">
                      {associatedSubtitle}
                    </Text>
                  </Col>
                </Row>
              </Col>

              {/* Files */}
              {associatedFiles.map((file) => (
                <Col span={24} key={file?.fileName}>
                  <Row gutter={[16, 16]} align={'middle'}>
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 8,
                      }}
                    >
                      <img
                        src={getIcon(getFileExtension(file?.fileName))}
                        alt="File icon"
                      />

                      <div
                        style={{
                          cursor: 'pointer',
                          whiteSpace: 'break-spaces',
                          overflow: 'hidden',
                        }}
                      >
                        <Text
                          small12
                          onClick={() => handleDownloadFile(file)}
                          className="text-normal"
                        >
                          {file?.fileName}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        );
      })}
    </>
  );
}

export default DocumentDisplay;
